const initState = {
  list: [],
  page: 1,
  limit: 20,
  count: 0,
};

const SearchReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_MENU_LIST":
      return {
        ...state,
        list: action.payload.data,
        page: action.payload.apage,
        limit: action.payload.limit,
        count: action.payload.count,
      };
    case "RESET_STATE":
      return {
        ...state,
        ...initState,
      };
    default:
      return { ...state };
  }
};

export default SearchReducer;

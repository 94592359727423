export const Login = (state = { loginFlag: false, values: {} }, action) => {
  switch (action.type) {
    case "LOGIN_WITH_JWT": {
      // console.log(action, "actionnnnnnnnnn");
      return { ...state, values: action.payload };
    }
    case "LOGOUT_WITH_JWT": {
      return { ...state, values: action.payload };
    }
    case "SET_LOGIN_FLAG":
      return { ...state, loginFlag: action.payload.data };
    default: {
      return state;
    }
  }
};
export default Login;

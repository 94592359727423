const initState = {
  extras: [],
  page: 1,
  limit: 20,
  sort: "",
};

const ExtrasReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_EXTRAS_LIST":
      return {
        ...state,
        extras: action.payload.data,

        
        page: action.payload.page,
        limit: action.payload.limit,
        sort: action.payload.sort,
      };

    default:
      return { ...state };
  }
};

export default ExtrasReducer;

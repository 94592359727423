const initState = {
    bookingAccess: [],
    page: 1,
    limit: 20,
    sort: "",
  };
  
  const bookingAccessReducer = (state = initState, action) => {
    switch (action.type) {
      case "SET_BOOKINGACCESS_LIST":
        return {
          ...state,
          bookingAccess: action.payload,
        };
  
      default:
        return { ...state };
    }
  };
  
  export default bookingAccessReducer;
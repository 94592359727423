export const setFacilitySearch = (category, arrival, departure, person) => {
  return {
    type: "SET_FACILITY_SEARCH",
    payload: { category, arrival, departure, person },
  };
};
export const setFacilitySearchList = (data, page, limit, count) => {
  return {
    type: "SET_FACILITY_LIST",
    payload: { data, page, limit, count },
  };
};


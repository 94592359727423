// import external modules
import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import authReducer from "./auth/authReducer";
import searchReducer from "./search/searchReducer";
import menuReducer from "./menu/menuReducer";
import facilityReducer from "./facility/facilityReducer";
import cartReducer from "./cart/cartReducer";
import ExtrasReducer from "./extras/extrasReducer";
import guestDetailsReducer from "./guestDetails/guestDetailsReducer";
import bookingReducer from "./bookings/bookingReducer";
import bookingAccessReducer from "./bookingAccess/bookingAccessReducer";
const rootReducer = combineReducers({
  toastr: toastrReducer,
  search: searchReducer,
  menu: menuReducer,
  auth: authReducer,
  facility: facilityReducer,
  cartData: cartReducer,
  extras: ExtrasReducer,
  guestDetails: guestDetailsReducer,
  bookings: bookingReducer,
  bookingAccess: bookingAccessReducer,
});

export default rootReducer;

const initState = {
    facility: [],
    page: 1,
    limit: 20,
    sort: "",
  };
  
  const guestDetailsReducer = (state = initState, action) => {
    switch (action.type) {
      case "GUEST_DETAILS":
        return {
          ...state,
          guestDetails: action.payload,
        };
  
      default:
        return { ...state };
    }
  };
  
  export default guestDetailsReducer;
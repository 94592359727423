const initState = {
    bookings: [],
    page: 1,
    limit: 20,
    sort: "",
  };
  
  const bookingReducer = (state = initState, action) => {
    switch (action.type) {
      case "SET_BOOKING_LIST":
        return {
          ...state,
          bookings: action.payload,
        };
  
      default:
        return { ...state };
    }
  };
  
  export default bookingReducer;
const initState = {
  category: "",
  arrival: 0,
  departure: 0,
  person: 0,
  list: [],
  page: 1,
  limit: 20,
  count: 0,
};

const SearchReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_FACILITY_SEARCH":
      return {
        ...state,
        category: action.payload.category,
        arrival: action.payload.arrival,
        departure: action.payload.departure,
        person: action.payload.person,
      };
    
    case "SET_FACILITY_LIST":
    return{
        ...state,
        list: action.payload.data,
        page: action.payload.page,
        limit: action.payload.limit,
        sort: action.payload.sort,
    };
    case "RESET_STATE":
      return {
        ...state,
        ...initState,
      };
    default:
      return { ...state };
  }
};

export default SearchReducer;
